import React from "react";
import MainDuyuru from "../Components/Body/MainDuyuru";

function HomePage() {
  return (
    <div>
      <MainDuyuru/>
    </div>
  );
}

export default HomePage;
