import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Link, useLocation, useParams } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useNavContext } from '../../Context/navContext';
import AnnouncementCard from "./AnnouncementCard";

function MainDuyuru() {
  const { navInfo } = useNavContext();
  const [records, setRecords] = useState([]);
  const [latestAnnouncements, setLatestAnnouncements] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 7;
  const location = useLocation();
  const { siteId } = useParams(); // siteId parametresini URL'den alıyoruz
  const clearPathMenu = location.pathname.replace(/^\/+/, '');
  // Verileri çekme fonksiyonu
  const fetchAllRecords = async () => {
    try {
      const res = await axios.get("https://www.duyurular.org/api/duyuru/first");

      // Aktif olmayan (pasif) ve null/boş olan kayıtları filtrele
      const filteredRecords = res.data.filter(record => record.active !== 'pasif');
      // Duyuru_date'yi kontrol et ve parse et
      const parseDate = (dateString) => {
        if (!dateString) return null;
        // Tarih ayırıcıyı kontrol et
        const separator = dateString.includes('.') ? '.' : '/';
        const [day, month, year] = dateString.split(separator).map(Number);
        return new Date(year, month - 1, day); // Aylar 0'dan başlar
      };

      // Sıralama işlemi (tarihe göre, aynı tarih varsa id'ye göre)
      const sortedRecords = filteredRecords.sort((a, b) => {
        const duyuruDateA = parseDate(a.duyuru_date);
        const duyuruDateB = parseDate(b.duyuru_date);

        if (duyuruDateA && duyuruDateB) {
          // Tarihe göre sıralama (en yeni önce)
          if (duyuruDateB - duyuruDateA !== 0) {
            return duyuruDateB - duyuruDateA;
          }
          // Aynı tarih için id'ye göre sıralama (id'si büyük olan önce)
          return b.id - a.id;
        }

        // Tarihlerden sadece biri varsa, var olanı üstte göster
        if (duyuruDateA) return -1;
        if (duyuruDateB) return 1;

        return 0; // İkisi de yoksa aynı sırada bırak
      });

      setRecords(sortedRecords);

      // En güncel 3 duyuruyu ayır
      setLatestAnnouncements(sortedRecords.slice(0, 3));

    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    fetchAllRecords();
  }, []); // Bileşen ilk yüklendiğinde veriyi çeker

  const handlePageChange = useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
    slowScrollToTop();
  }, []); // Fonksiyon sabit kalacaksa bağımlılıkları boş bırakabilirsiniz
  // navInfo değiştiğinde verileri yeniden çek
  useEffect(() => {
    handlePageChange(1);
    setRecords([]); // Önce tüm kayıtları sıfırla
    fetchAllRecords(); // Ardından verileri yeniden çek

  }, [navInfo, handlePageChange]); // navInfo değiştiğinde verileri yeniden çek

  // siteId varsa ona göre filtrele
  const filteredRecords = records.filter(record => {
    if (siteId) {
      return record.website_id === parseInt(siteId); // siteId parametresine göre filtreleme
    }

    return navInfo | clearPathMenu === 'kurumlar' ? record.tag === 'Kurumlar' :
      navInfo | clearPathMenu === 'cumhurbaskanligi' ? record.tag === 'Cumhurbaskanliği' :
        navInfo | clearPathMenu === 'bakanliklar' ? record.tag === 'Bakanliklar' :
          navInfo | clearPathMenu === 'resmi-gazete' ? record.tag === 'Resmi-Gazete' :
            true; // Diğer durumlarda tüm kayıtları al
  }
  );

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredRecords.slice(indexOfFirstRecord, indexOfLastRecord);
  const slowScrollToTop = () => {
    const scrollHeight = window.scrollY;
    const scrollStep = scrollHeight / 100;
    const delay = 4;

    const scrollAnimation = () => {
      if (window.scrollY > 0) {
        window.scrollTo(0, window.scrollY - scrollStep);
        setTimeout(scrollAnimation, delay);
      }
    };
    scrollAnimation();
  };

  //surekli yenileme--------------------------------------------------------------
  const compareAllRecords = useCallback(async () => {
    try {
      // compareRes verisini çekiyoruz
      const compareRes = await axios.get("https://www.duyurular.org/api/duyuru/first");
      // "active" durumu "pasif" olmayanları filtreliyoruz
      const filteredCompare = compareRes.data.filter(record => record.active !== 'pasif');
      
      // filteredCompare listesini id'ye göre azalan sırayla sıralıyoruz
      filteredCompare.sort((a, b) => b.id - a.id);
      
      // records[0] ile filteredCompare[0] id'lerini karşılaştırıyoruz
      if (records[0]?.id !== filteredCompare[0]?.id) {
        // Eğer id'ler farklıysa fetchAllRecords fonksiyonunu çağırıyoruz
        fetchAllRecords();
      }
    } catch (error) {
      console.error("Error fetching records:", error);
    }
  }, [records]);  // filteredRecords bağımlılığını ekledik
  

  useEffect(() => {
    const intervalId = setInterval(() => {
      compareAllRecords();
    }, 300000); // 5 dakika

    // Temizlik işlemi
    return () => clearInterval(intervalId);
  }, [compareAllRecords]); // Artık bağımlılık listesi tamamlandı
  //surekli yenileme--------------------------------------------------------------


  return (
    <section className="section wb">
      <div className="container">
        <div className="row" style={{ marginTop: '-40px' }}>
          <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
            <div className="page-wrapper">
              {siteId ? <h4>{currentRecords[0]?.name} duyuruları</h4> : ''}
              {currentRecords.map((record, index) => (
                <div key={index} className="blog-list clearfix">
                  <AnnouncementCard record={record} />
                </div>
              ))}
            </div>
            <hr className="invis" />
            <div className="row">
              <div className="col-md-12">
                <nav aria-label="Page navigation">
                  <ul className="pagination justify-content-start">
                  {currentPage > 3 && (
                      <li className={`page-item ${indexOfLastRecord >= filteredRecords.length ? "disabled" : ""}`}>
                        <Link className="page-link" onClick={() => handlePageChange(currentPage - 1)} to="#">
                          <span style={{ color: 'white' }}>Geri</span>
                        </Link>
                      </li>            
                    )}
                    <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                      <Link className="page-link" onClick={() => handlePageChange(1)} to="#">
                        1
                      </Link>
                    </li>
                    <li className={`page-item ${currentPage === 2 ? "disabled" : ""}`}>
                      <Link className="page-link" onClick={() => handlePageChange(2)} to="#">
                        2
                      </Link>
                    </li>
                    <li className={`page-item ${currentPage === 3 ? "disabled" : ""}`}>
                      <Link className="page-link" onClick={() => handlePageChange(3)} to="#">
                        3
                      </Link>
                    </li>
                    {currentPage > 3 && (
                      <>
                        <li className="page-item">
                          <span className="page-link">...</span>
                        </li>
                        <li className={`page-item ${currentPage > 3 ? "disabled" : ""}`}>
                          <Link className="page-link" onClick={() => handlePageChange(currentPage)} to="#">
                            <span style={{ color: 'black' }}>{currentPage}</span>
                          </Link>
                        </li>
                      </>
                    )}
                    {currentPage >= 3 && (
                      <li className={`page-item ${indexOfLastRecord >= filteredRecords.length ? "disabled" : ""}`}>
                        <Link className="page-link" onClick={() => handlePageChange(currentPage + 1)} to="#">
                          <span style={{ color: 'white' }}>İleri</span>
                        </Link>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <Sidebar latestAnnouncements={latestAnnouncements} />
        </div>
      </div>
    </section>
  );
}

export default MainDuyuru;
