// Header.js
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Link, useNavigate } from "react-router-dom";
import { useNavContext } from "../../Context/navContext"; // Context'i import et

const Header = () => {
  const { setNavInfo } = useNavContext(); // Context'ten değeri al
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isCollapsing, setIsCollapsing] = useState(false);
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");

  const handleNavClick = (navItem) => {
    setNavInfo(navItem); // Tıklanan butona göre navInfo'yu ayarla 
  };

  const handleToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleSearchBar = () => {
    if (isSearchOpen) {
      setIsCollapsing(true);
      setTimeout(() => {
        setIsCollapsing(false);
        setIsSearchOpen(false);
      }, 300); // CSS transition süresiyle aynı olmalı
    } else {
      setIsCollapsing(true);
      setTimeout(() => {
        setIsCollapsing(false);
        setIsSearchOpen(true);
      }, 300); // CSS transition süresiyle aynı olmalı
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchInput.trim() !== "") {
      navigate(`/search/${searchInput.trim()}`);
    }
  };


  return (
    <>
      <div
        className={`top-search ${isCollapsing ? "collapsing" : ""} ${isSearchOpen ? "collapse show" : "collapse"
          }`}
        id="collapseExample"
        style={{
          maxHeight: isSearchOpen ? "150px" : "0",
          overflow: "hidden",
          transition: "max-height 0.5s ease-out !important",
        }}
      >
        <div className="card card-block">
          <div className="newsletter-widget text-center">
            <form className="form-inline" onSubmit={handleSearchSubmit}>
              <input
                type="text"
                className="form-control"
                placeholder="Aramak istediğiniz kelimeyi giriniz"
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <button type="submit" className="btn btn-primary">
                <SearchIcon />
              </button>
            </form>
          </div>
        </div>
      </div>

      <div className="topbar-section" style={{ margin: 0, padding: 0 }}>
        <div
          className="container-fluid"
          style={{
            maxWidth: "1200px", // Maksimum genişlik
            margin: "0 auto", // Ortalamak için
            padding: "0 15px", // Yanlardan daraltmak için padding
          }}
        >
          <div
            className="row"
            style={{
              alignItems: "center",
              height: "70px", // Topbar yüksekliği
              margin: 0,
              padding: 0,
            }}
          >
            <div className="col-lg-4 col-md-6 col-sm-6 hidden-xs-down">
              <div
                className="topsocial"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <a
                  href="https://www.linkedin.com/company/duyurular-org/"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="LinkedIn"
                >
                  <i className="fa fa-linkedin" />
                </a>
              </div>
            </div>

            <div
              className="col-lg-4 hidden-md-down"
              style={{
                height: "100%", // Tam yüksekliği kaplaması için
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                margin: 0,
                padding: 0,
              }}
            >
              <img
                style={{ maxHeight: "50px", objectFit: "contain" }}
                src="https://www.duyurular.org/duyurular_images/duyurular.org_logo.png"
                alt="Logo"
              />
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
              <div
                className="topsearch text-right"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <span
                  data-toggle="collapse"
                  onClick={handleSearchBar}
                  aria-expanded={isSearchOpen}
                  aria-controls="collapseExample"
                  className="collapsed"
                  style={{
                    marginLeft: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <SearchIcon style={{ verticalAlign: "middle" }} />
                  <span style={{ marginLeft: "5px" }}>Ara</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="header-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="logo">
                <a href="https://biomedista.com/">
                  <img
                    src="https://www.duyurular.org/duyurular_images/biomedista.jpg"
                    alt="Logo"
                    className="img-fluid"  // Bootstrap img-fluid sınıfı ile logo responsive yapılır
                    style={{
                      width: "70%",           // Normal boyutlarda genişliği %80 yap
                      height: "auto",         // Yükseklik orantılı olarak ayarlanır
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <style>
          {`
      @media (max-width: 767px) {  /* Mobil cihazlar için */
        .logo img {
          width: 100% !important;  /* Mobilde logo genişliğini %100 yap */
        }
      }
    `}
        </style>
      </div>


      <header className="header">
        <div className="container">
          <nav className="navbar navbar-expand-md navbar-inverse">
            <button
              className="navbar-toggler"
              type="button"
              onClick={handleToggle}
              aria-controls="ForestTimemenu"
              aria-expanded={isNavOpen}
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`collapse navbar-collapse ${isNavOpen ? "show" : ""}`}
              id="ForestTimemenu"
            >
              <ul className="navbar-nav mx-auto">
                <li className="nav-item">
                  <Link className="nav-link color-green-hover" to="/anasayfa" onClick={() => handleNavClick("anasayfa")}>
                    Anasayfa
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link color-green-hover" to="/cumhurbaskanligi" onClick={() => handleNavClick("cumhurbaskanligi")}>
                    Cumhurbaşkanlığı
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link color-green-hover" to="/bakanliklar" onClick={() => handleNavClick("bakanliklar")}>
                    Bakanlıklar
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link color-green-hover" to="/kurumlar" onClick={() => handleNavClick("kurumlar")}>
                    Kurumlar
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link color-green-hover" to="/resmi-gazete" onClick={() => handleNavClick("resmi-gazete")}>
                    Resmi Gazete
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link color-green-hover" to="/iletisim" onClick={() => handleNavClick("iletisim")}>
                    İletişim
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>

      <style>
        {`
          @media (min-width: 768px) {
            .navbar-toggler {
              display: none; 
            }
            .navbar-collapse {
              display: flex !important; 
              flex-direction: row; 
            }
            .navbar-nav {
              flex-direction: row;
              margin: 0 auto; /* Ortalamak için */
            }
            .nav-item {
              margin-left: 1rem; 
            }
          }

          @media (max-width: 767px) {
            .navbar-nav {
              flex-direction: column;
              width: 100%;
              margin: 0; /* Ortalamak için */
            }
            .nav-item {
              margin-left: 0;
              margin-bottom: 1rem;
              text-align: center; /* Mobil görünümde yazıları ortalamak için */
            }
          }
        `}
      </style>
    </>
  );
};

export default Header;
